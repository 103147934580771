<template>
  <BModal ref="submit-confirm" centered hide-header hide-footer no-close-on-backdrop no-close-on-esc>
    <BRow class="px-1 py-2">
      <BCol cols="12" class="d-flex justify-content-center mb-1">
        <BImg
          src="https://storage.googleapis.com/komerce/core/icon-popup-warning.png"
        />
      </BCol>
      <BCol cols="12" class="text-center d-flex justify-content-center my-1">
        <h4 class="text-black">
          Semua data yang kamu masukan di Speadsheet akan menjadi Order
        </h4>
      </BCol>
      <BCol cols="12" class="d-flex align-items-center justify-content-center">
        <BButton variant="outline-primary" class="cancel-btn mr-1" @click="close">
          <h5 class="mb-0 text-primary">
            <strong>
              Batal
            </strong>
          </h5>
        </BButton>
        <BButton variant="primary" class="submit-btn" @click="$emit('submit')">
          <h5 class="mb-0 text-white">
            <strong>
              Submit
            </strong>
          </h5>
        </BButton>
      </BCol>
    </BRow>
  </BModal>
</template>

<script>
export default {
  methods: {
    show() {
      this.$refs['submit-confirm'].show()
    },
    close() {
      this.$refs['submit-confirm'].hide()
    },
  },
}
</script>

<style scoped>
    .cancel-btn, .submit-btn {
        border-radius: 12px;
        height: 55px;
        width: 120px;
    }
</style>
